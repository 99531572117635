'use client';

import React from 'react';
import NextLink from 'next/link';

interface AnimatedLinkButtonProps {
  href: string;
  children: React.ReactNode;
  isExternal?: boolean;
}

const AnimatedLinkButton = ({
  href,
  children,
  isExternal = false,
}: AnimatedLinkButtonProps) => {
  return (
    <NextLink
      href={href}
      target={isExternal ? '_blank' : undefined}
      className="inline-block"
    >
      <button
        className="
          relative 
          px-8 
          py-3.5 
          text-base 
          sm:text-lg 
          font-semibold
          rounded-full
          border-[1.5px]
          border-neutral-800
          bg-white 
          text-neutral-800
          transition-all 
          duration-300
          ease-out
          hover:bg-neutral-50
          hover:-translate-y-0.5
          hover:shadow-[0_4px_12px_rgba(0,0,0,0.08)]
          group
        "
      >
        <span
          className="
            relative 
            flex 
            items-center 
            gap-3
            tracking-wide
          "
        >
          {children}
          {isExternal && (
            <i
              className="
                ri-external-link-line
                transform 
                transition-transform 
                duration-300 
                group-hover:translate-x-0.5
                group-hover:-translate-y-0.5
              "
            />
          )}
        </span>
      </button>
    </NextLink>
  );
};

export default AnimatedLinkButton;
